import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {ILoanOffer} from '../application/types'

@Injectable({
  providedIn: 'root'
})
export class LoanService {

  /**
   * Extra options for the http requests. Typically, the authorization header.
   */
  private httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'no-good'
    })
  }

  constructor(
    private httpClient: HttpClient
  ) {
  }

  /**
   * Set the authentication token
   *
   * @param token - After sign an access token is received. This token has to be set here.
   */
  public setToken(token: string): void {
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization', `Bearer ${token}`)
  }

  /**
   * Create an application, This sends the application to the
   * backend for sanitation and then a BankID sign process is
   * started and a references is returned.
   */
  public apply(application: any): Observable<string> {
    const url = `${environment.loginServiceUrl}/blanco`
    return this.httpClient.put<string>(url, application)
  }

  /**
   * Checks a personnummer for validity. This calls the
   * common personnummer lambda on service account.
   */
  public validatePersonNummer(id: string): Observable<any> {
    const url = `${environment.authServiceUrl}/personnummer`
    return this.httpClient.put<void>(url, {id})
  }

  /**
   * Retrieves on application based on personnummer. This is used
   * by the applicant and personnummer is fetched from access token.
   * The access token is received when collect is completed.
   */
  public getBlanco(): Observable<ILoanOffer> {
    const url = `${environment.loginServiceUrl}/loan`
    return this.httpClient.get<ILoanOffer>(url, this.httpOptions)
  }

  public rejectOffer(): Observable<void> {
    const url = `${environment.loginServiceUrl}/loan`
    return this.httpClient.delete<void>(url, this.httpOptions)
  }
}
